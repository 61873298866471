import React from "react";
import "./Area.css";
import inds from "../../Images/inds.png";
import mainimg from "../../Images/maintanience.png";
import grasslet from "../../Images/grasslet.png";
import Garbage from "../../Images/garbage.png";
import high from "../../Images/hig.png";
export default function Area() {
  return (
    <div className="Area">
      <section className="section_all bg-light areaX" id="about">
        <div className="backLetter"> EXPERTISE</div>
        <div className="container area">
          <div className="row">
            <div className="col-lg-12">
              <div className="section_title_all text-center">
                <div className="heading" align="center">
                  <label style={{ marginRight: "30px" }}></label>
                  <span data-aos="zoom-in-left">Expertises</span>
                  <label style={{ marginLeft: "30px" }}></label>
                </div>
                <br />
                <br />
                <p
                  className="section_subtitle mx-auto text-muted goldcolor"
                  data-aos="zoom-in-left"
                  style={{ fontWeight: 900 }}
                >
                  {/* Where We Specialise */}
                  WHERE WE SPECIALISE
                </p>
                <div className="">
                  <i className=""></i>
                </div>
              </div>
            </div>
          </div>

          <div className="row vertical_content_manage mt-5">
            <div className="col-lg-4">
              <div className="about_header_main mt-3">
                <div className="about_icon_box">
                  <p
                    className="text_custom font-weight-bold"
                    data-aos="zoom-in-left"
                  >
                    Gold Star General Maintenance & Cleaning Services LLC
                    specializes in providing top-quality cleaning solutions for
                    residential, commercial, and industrial clients, ensuring
                    exceptional results and unmatched customer satisfaction.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row mt-3">
                <div
                  className="col-lg-4 bx"
                  data-aos="fade-down-right"
                  align="center"
                >
                  <img src="https://gatewaypropertyservices.com.au/wp-content/uploads/2022/09/shoppingcleaning_vks1.jpg" />
                  <h4>Shopping Mall</h4>
                </div>
                <div
                  className="col-lg-4 bx"
                  data-aos="fade-down-right"
                  align="center"
                >
                  <img src={inds} />

                  <h4>Industrial & Cleanroom Cleaning</h4>
                </div>
                <div
                  className="col-lg-4 bx"
                  data-aos="fade-down-right"
                  align="center"
                >
                  <img src={mainimg} />

                  <h4>Maintenance</h4>
                </div>
              </div>
              <div className="row mt-3">
                <div
                  className="col-lg-4 bx"
                  data-aos="fade-down-right"
                  align="center"
                >
                  <img src={grasslet} /> <h4>Landscape & Ground</h4>
                </div>
                <div
                  className="col-lg-4 bx"
                  data-aos="fade-down-right"
                  align="center"
                >
                  <img src={high} />

                  <h4>High Rise Facade Cleaning</h4>
                </div>
                <div
                  className="col-lg-4 bx"
                  data-aos="fade-down-right"
                  align="center"
                >
                  <img src={Garbage} />

                  <h4>Garbage chute cleaning</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
