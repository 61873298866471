import React from "react";
import "./Service.css";

export default function Service() {
  return (
    <div className="service">
      <section className="section_all" id="about">
        <div className="container">
          <div className="heading" align="center">
            <br />
            <br />
            <label style={{ marginRight: "30px" }}></label>
            <span>Policies</span>
            <label style={{ marginLeft: "30px" }}></label>
          </div>

          <br />
          <br />
          <div className="row" data-aos="zoom-in" align="center">
            <p className="goldcolor" style={{ textTransform: "uppercase" }}>
              Gold Star General Maintenance & Cleaning Services LLC prioritizes
              <br />
              training, quality, safety, sustainability, <br /> customer
              satisfaction, and employee wellness.
            </p>
          </div>
          <div className="serviceTab">
            <div className="row">
              <div className="col col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                <div className="services" data-aos="zoom-in">
                  <div className="icon">
                    <ion-icon name="heart"></ion-icon>
                  </div>
                  <div>
                    <h5>Employment Training Program.</h5>

                    <p>
                      Our programme provides essential skills and career
                      coaching to help individuals secure meaningful employment
                      and advance in their careers.
                    </p>
                    <div className="serviceBtn">
                      <ion-icon name="arrow-forward-outline"></ion-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                <div className="services" data-aos="zoom-in">
                  <div className="icon">
                    <ion-icon name="train-outline"></ion-icon>
                  </div>
                  <div>
                    <h5>Health & Safety Policies.</h5>
                    <p>
                      Our health and safety policies have been organized,
                      implemented and monitored with the company safety rules
                      and guidelines.
                    </p>

                    <div className="serviceBtn">
                      <ion-icon name="arrow-forward-outline"></ion-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                <div className="services" data-aos="zoom-in">
                  <div className="icon">
                    <ion-icon name="diamond-outline"></ion-icon>
                  </div>
                  <div>
                    <h5>Recycling Policy.</h5>
                    <p>
                      Goldststar cleaning services will workalongside your
                      organization to ensureallEmployees are re-trained and
                      ensure yourrecycling policy iscorrectly followed.
                    </p>

                    <div className="serviceBtn">
                      <ion-icon name="arrow-forward-outline"></ion-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
