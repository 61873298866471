import React from "react";
import "./About.css";

export default function About() {
  return (
    <div>
      <section className="section_all bg-light" id="about">
        <div className="backLetter">ABOUT US</div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section_title_all text-center">
                <div className="heading" align="center">
                  <label style={{ marginRight: "30px" }}></label>
                  <span data-aos="zoom-in-left">About Us</span>
                  <label style={{ marginLeft: "30px" }}></label>
                </div>

                <br />
                <br />
                <p
                  className="section_subtitle mx-auto text-muted goldcolor"
                  data-aos="zoom-in-left"
                  style={{ fontWeight: 900 }}
                >
                  WE ARE GOLDSTAR CLEANING SERVICES LLC
                </p>
                <div className="">
                  <i className=""></i>
                </div>
              </div>
            </div>
          </div>

          <div className="row vertical_content_manage mt-5">
            <div className="col-lg-6">
              <div className="about_header_main mt-3">
                <div className="about_icon_box">
                  <p
                    className="text_custom font-weight-bold goldcolor"
                    data-aos="zoom-in-left"
                    style={{ color: "#be8f2d" }}
                  >
                    We offer full-fledge of cleaning services to customer.
                  </p>
                </div>
                <h4
                  className="about_heading text-capitalize font-weight-bold mt-4"
                  data-aos="zoom-in-left"
                >
                  We began to offering full-fledge of cleaning and maintenance
                  services to customers, due to customer demand that push
                  company to diversify our services.
                </h4>
                <p className="text-muted mt-3" data-aos="zoom-in-left">
                  We are passionate about providing a flexible and customized
                  services where every customer is treated equally no matter how
                  large and small the contract value. Wewill ensure that every
                  contract is maintained at the consistent level of quality by
                  doing one-off spot checks or scheduled visits, tested process
                  and methods deliveredby our skilled employees..
                </p>

                <p className="text-muted mt-3" data-aos="zoom-in-left">
                  Our areas of expertise include Industrial, Commercial,
                  Corporate Office, Hospital and Residential Cleaning and
                  maintenance. We aim to providing quality services to our
                  customer as we believe that clean and healthy environment is
                  beneficial to one’s physical and mental health.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="img_about mt-3">
                <img
                  src="https://cdn.prod.website-files.com/60ff934f6ded2d17563ab9dd/61392e6fd5cd0346ca9216a6_commercial-cleaning-services-rates.jpeg"
                  alt=""
                  className="img-fluid mx-auto d-block"
                />
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-4">
              <div className="about_content_box_all mt-3">
                <div className="about_detail text-center">
                  <div className="about_icon">
                    <i
                      className="fas fa-pencil-alt"
                      style={{ color: "green" }}
                    ></i>
                  </div>
                  <h5
                    className="text-dark text-capitalize mt-3 font-weight-bold goldcolor"
                    data-aos="zoom-in-left"
                  >
                    Our Mission
                  </h5>
                  <p
                    className="edu_desc mt-3 mb-0 text-muted"
                    data-aos="zoom-in-left"
                  >
                    To provide professional cleaning services to both commercial
                    and domestic customers that exceeds all expectation in
                    regards to effectiveness, cost, environment and customers
                    satisfaction.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="about_content_box_all mt-3">
                <div className="about_detail text-center">
                  <div className="about_icon">
                    <i
                      className="fab fa-angellist zracolor"
                      style={{ color: "green" }}
                    ></i>
                  </div>
                  <h5
                    className="text-dark text-capitalize mt-3 font-weight-bold goldcolor"
                    data-aos="zoom-in-left"
                  >
                    Our Vision
                  </h5>
                  <p
                    className="edu_desc mb-0 mt-3 text-muted"
                    data-aos="zoom-in-left"
                  >
                    To meet the growing demand of maintenance & cleaning
                    industry with the latest technology innovations in order to
                    satisfy our customer requirements.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="about_content_box_all mt-3">
                <div className="about_detail text-center">
                  <div className="about_icon">
                    <i
                      className="fas fa-paper-plane"
                      style={{ color: "green" }}
                    ></i>
                  </div>
                  <h5
                    className="text-dark text-capitalize mt-3 font-weight-bold goldcolor"
                    data-aos="zoom-in-left"
                  >
                    Our Objective
                  </h5>
                  <p
                    className="edu_desc mb-0 mt-3 text-muted"
                    data-aos="zoom-in-left"
                  >
                    To ensure that our customer gets the best service available
                    in the Cleaning Industry, providing a Premium and Luxurious
                    service and ultimately exceed customer expectation.
                    <br />
                    <br />
                    <div
                      align="right"
                      className="zracolor"
                      data-aos="zoom-in-left"
                    >
                      <label>Learn More</label>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
