import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import About from "./Components/About/About";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Service from "./Components/Services/Service";
import Sponsor from "./Components/Sponsor/Sponsor";
import Main from "./Components/Main/Main";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import Area from "./Components/Area/Area";
import LoadingScreen from "./Components/Loader";
import Office from "./Images/office2.mp4";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Contact from "./Components/Contact/Contact";
import ServicePage from "./Pages/Services/ServicePage";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    AOS.init();
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/Contact">
          <Contact />
        </Route>
        {/* <Route path="/Service">
          <ServicePage />
        </Route> */}
        <Route path="/users"></Route>
        <Route path="/">
          <Main Office={Office} />
          <About />
          <Area />
          <Sponsor />
          <Service />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
