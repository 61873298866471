import React from "react";
import "./Main.css";
// import Office from "../../Images/office2.mp4";

export default function Main(props) {
  return (
    <div className="Main">
      <div className="container">
        <video autoPlay loop muted id="video">
          <source src={props.Office} type="video/mp4" />
        </video>
        <p data-aos="fade-up">We bring the best for you</p>
        <h3 data-aos="fade-up">
          Goldstar Cleaning Services was set-up <br />
          as an outsourcing company in recruiting
          <br /> and managing of foreign workers.
        </h3>
        <div>
          <button data-aos="fade-up">OUR SERVICES</button>
          <button data-aos="fade-up">GET IN TOUCH</button>
        </div>
        <div className="floatDescription">
          <div className="row">
            <div className="col">
              <h4 data-aos="fade-up">
                Our areas of expertise include Industrial, Commercial, Corporate
                Office, Hospital and Residential Cleaning and maintenance.
              </h4>
              <p data-aos="fade-up">
                At Goldstar cleaning services, we understand that establishing
                positive and effective leadership is a key factor in proving our
                customers with excellent services. We also implement Health
                & Safety and Recycling Policies that have been through both
                experience andknowledge and are in line with best practice
                management..
              </p>
            </div>
            <div className="col-3">
              <br />
              <br />
              <button data-aos="fade-up">LEARN MORE</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
