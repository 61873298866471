import React from "react";
import "./Sponsor.css";
import SponsorImg from "../../Images/sponsor1.png";
import SponsorImgSmall from "../../Images/smallsponsort.png";

export default function Sponsor() {
  return (
    <div>
      <div className="container">
        <div className="backLetter">SPONSORS</div>

        <div id="sponsorSection" className="contaier-fluid">
          <br />
          <br />
          <div className="heading" align="center" data-aos="zoom-in">
            <label style={{ marginRight: "30px" }}></label>
            <span>Sponsor</span>
            <label style={{ marginLeft: "30px" }}></label>
          </div>
          <br />
          <br />
          <div className="row" data-aos="zoom-in">
            <p style={{ textTransform: "uppercase", color: "#be8f2d" }}>
              We would like to thank the following companies and organizations
              for supporting us
            </p>
          </div>
          <br />
          <br />

          <div>
            <img
              className="img-responsive"
              src={SponsorImg}
              alt="The Hatch"
              // width="50%"
            />
          </div>

          <div className="row" style={{ display: "none" }}>
            <div
              className="col col-sm-4 col-xs-12 sponsorLogo"
              data-aos="zoom-in"
            >
              <a href="https://www.theironyard.com/" target="_blank">
                <img
                  className="img-responsive"
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVeo7ZkZuneYaPx7LNft7S0qIgg5DtUtBeQA&s"
                  alt="The Iron Yard"
                />
              </a>
            </div>
            <div
              className="col col-sm-4 col-xs-12  sponsorLogo"
              data-aos="zoom-in"
            >
              <a href="https://thehatchcreates.com/" target="_blank">
                <img
                  className="img-responsive"
                  src="https://th.bing.com/th/id/OIP.QfW12hGe6FfIY8l_WJebQQHaE8?pid=ImgDet&rs=1"
                  alt="The Hatch"
                  height="150px"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
