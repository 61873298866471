import React from "react";
import "./Contact.css";

export default function Contact() {
  return (
    <div>
      <div className="contactPage">
        <div>
          <h3>How Can We Help Us?</h3>
          <br />
          <h4>Send us a message</h4>
        </div>
      </div>
      <div className="container">
        <div className="container">
          <div className="innerwrap">
            <section className="section1 clearfix">
              <div className="textcenter">
                <span className="shtext">Contact Us</span>
                <span className="seperator"></span>
                <h1>Drop Us a Mail</h1>
              </div>
            </section>

            <section className="section2 clearfix">
              <div className="col2 column1 first">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3630.6915886573825!2d54.37716817591376!3d24.496140659737794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e67080559a9fb%3A0xb686bbc8d9762e8e!2sGold%20Star%20Cleaning%20Services!5e0!3m2!1sen!2sae!4v1720282240995!5m2!1sen!2sae"
                  width="600"
                  height="650"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              <div className="col2 column2 last">
                <div className="sec2innercont">
                  <div className="sec2addr">
                    <p>
                      F3-12, Zig Zag Building, near Dana Hotel, Tourist Club
                      Area, Abu Dhabi, United Arab Emirates
                    </p>
                    <p>
                      <span className="collig">Phone :</span>+971525738851 |
                      +97128783394 |
                    </p>
                    <p>
                      <span className="collig">Email :</span>{" "}
                      info@goldstaruae.com | admin@goldstaruae.com
                    </p>
                  </div>
                </div>
                <div className="sec2contactform">
                  <h3 className="sec2frmtitle" style={{ color: "#be8f2d" }}>
                    Want to Know More? Drop Us a Mail
                  </h3>
                  <form action="">
                    <div className="clearfix">
                      <input
                        className="col2 first"
                        type="text"
                        placeholder="First Name"
                      />
                      <input
                        className="col2 last"
                        type="text"
                        placeholder="Last Name"
                      />
                    </div>
                    <div className="clearfix">
                      <input
                        className="col2 first"
                        type="Email"
                        placeholder="Email"
                      />
                      <input
                        className="col2 last"
                        type="text"
                        placeholder="Contact Number"
                      />
                    </div>
                    <div className="clearfix">
                      <textarea name="textarea" id="" cols="30" rows="7">
                        Your message here...
                      </textarea>
                    </div>
                    <div className="clearfix">
                      <input type="submit" value="Send" />
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
